import "./FromAndToComponent.less";
import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { MailboxItem, OfficeWrapper } from "../../../services/OfficeWrapper";
import { IPersonaProps, ITag, MessageBarType } from "office-ui-fabric-react";
import { FormValidationHelpers } from "../../../helpers/FormValidationHelpers";
import EmailAddressDetails = Office.EmailAddressDetails;
import AssigneeComponent from "../assignee/AssigneeComponent";
import { Logger } from "../../../services/Logger";
import { IProjectsService } from "../../../services/NewformaApi/IProjectsService";
import { TeamMemberNormalized } from "../../../models/projectTeam/TeamViewResponse";
import LinkComponent from "../linkComponent/LinkComponent";
import { EmailRecipients } from "../../../models/shared/FileTransferEmailFieldsDetails";
import * as _ from "lodash";

export interface FromAndToComponentProps extends LocalizeContextProps {
    className?: string;
    isFromRequired: boolean;
    isToRequired: boolean;
    isCcRequired?: boolean;
    includeCc: boolean;
    officeWrapper: OfficeWrapper;
    onFromChange: (people: IPersonaProps[]) => void;
    onToChange: (people: IPersonaProps[]) => void;
    onCcChange?: (people: IPersonaProps[]) => void;
    disabled: boolean;
    formValidationHelpers: FormValidationHelpers;
    mailboxItem: MailboxItem | null;
    project: ITag | null;
    logger: Logger;
    projectsService: IProjectsService;
    onError: (message: string | null, type: MessageBarType) => void;
    shouldHideFrom: boolean;
    shouldClearEmails?: boolean;
    isTransferFailed?: boolean;
    EmailRecipients?: EmailRecipients;
    isFileTransferComponent?: boolean;
    isForwardSubmittalInProgress?: boolean;
    isForwardRfiInProgress?: boolean;
}

export interface FromAndToComponentState {
    from: IPersonaProps[];
    to: IPersonaProps[];
    cc: IPersonaProps[];
    showCcField: boolean;
    isLoadingTeamMembers: boolean;
}

class FromAndToComponent extends React.Component<FromAndToComponentProps, FromAndToComponentState> {
    constructor(props: FromAndToComponentProps, context: FromAndToComponentState) {
        super(props, context);

        this.state = {
            from: [],
            to: [],
            cc: [],
            showCcField: false,
            isLoadingTeamMembers: false,
        };
    }

    componentDidMount() {
        this.populateFields();
    }

    async componentDidUpdate(
        prevProps: Readonly<FromAndToComponentProps>,
        prevState: Readonly<FromAndToComponentState>,
        snapshot?: any
    ): Promise<void> {
        if (prevProps.mailboxItem !== this.props.mailboxItem) {
            this.populateFields();
            return;
        }

        if (this.props.project?.key && prevProps.project?.key !== this.props.project.key) {
            this.populateFields();
            await this.lookupContacts(this.props.project.key);
            return;
        }
        this.addOrRemoveExpandableClass();
    }

    private populateFields(): void {
        if (this.props.shouldClearEmails) {
            this.setState({
                from: [],
                to: [],
                cc: [],
            });
            return;
        }
        const from = this.props.officeWrapper.getSenderEmailAddress();
        const to = this.props.officeWrapper.getCurrentEmailToRecipients();
        const cc = this.props.officeWrapper.getCurrentEmailCcRecipients();

        const fromAsPersonaProps =
            this.props.isForwardSubmittalInProgress &&
            this.props.EmailRecipients &&
            this.props.EmailRecipients.from?.length
                ? this.props.EmailRecipients?.from
                : from
                ? [{ text: from }]
                : [];
        const toAsPersonaProps = this.props.isForwardSubmittalInProgress
            ? this.getFieldForForwardSubmittal("to")
            : this.props.isTransferFailed && this.props.EmailRecipients && this.props.EmailRecipients.to?.length
            ? this.props.EmailRecipients?.to
            : this.removeNewformaAutoFileEmail(to);

        const ccAsPersonaProps = this.props.isForwardSubmittalInProgress
            ? this.getFieldForForwardSubmittal("cc")
            : this.props.isTransferFailed && this.props.EmailRecipients && this.props.EmailRecipients.cc?.length
            ? this.props.EmailRecipients?.cc
            : this.removeNewformaAutoFileEmail(cc);

        this.props.onFromChange(
            this.props.isFileTransferComponent ? this.removeNewformaAutoFileEmail(to) : fromAsPersonaProps
        );
        this.props.onToChange(
            this.props.isFileTransferComponent && !this.props.isTransferFailed ? fromAsPersonaProps : toAsPersonaProps
        );
        if (this.props.onCcChange) {
            this.props.onCcChange(ccAsPersonaProps);
        }

        this.setState({
            from: this.props.isFileTransferComponent ? this.removeNewformaAutoFileEmail(to) : fromAsPersonaProps,
            to:
                this.props.isFileTransferComponent && !this.props.isTransferFailed
                    ? fromAsPersonaProps
                    : toAsPersonaProps,
            cc: ccAsPersonaProps,
            showCcField: !!(ccAsPersonaProps.length && this.props.includeCc),
        });
    }

    private async lookupContacts(projectNrn: string): Promise<void> {
        try {
            this.setState({ isLoadingTeamMembers: true });
            const allTeamMembers = await this.props.projectsService.getAllTeamMembersNormalized(projectNrn);
            const updatedFrom = this.convertEmailsToNewformaTeamMember(this.state.from, allTeamMembers);
            const updatedTo = this.convertEmailsToNewformaTeamMember(this.state.to, allTeamMembers);
            const updatedCc = this.convertEmailsToNewformaTeamMember(this.state.cc, allTeamMembers);

            this.setState({ from: updatedFrom, to: updatedTo, cc: updatedCc });
        } catch (error) {
            this.props.logger.error("FromAndToComponent failed to load team members", error);
        } finally {
            this.setState({ isLoadingTeamMembers: false });
        }
    }

    private convertEmailsToNewformaTeamMember(
        prepopulatedPersonas: IPersonaProps[],
        allTeamMembers: TeamMemberNormalized[]
    ): IPersonaProps[] {
        return prepopulatedPersonas.map((contact) => {
            if ((contact as any).data) {
                // if data is set then persona already from project team
                return contact;
            }
            const teamMembers = allTeamMembers.filter((member) => member.email === contact.text);
            if (!teamMembers.length || teamMembers.length > 1) {
                return contact;
            }
            const teamMember = teamMembers[0];
            return this.props.formValidationHelpers.getPersonaPropsFromTeamMembers([teamMember])[0];
        });
    }

    private removeNewformaAutoFileEmail(recipients: EmailAddressDetails[]): IPersonaProps[] {
        return recipients
            .map((recipient) => recipient.emailAddress)
            .filter((email) => !email.toLowerCase().endsWith("newforma.email"))
            .map((email) => ({ text: email }));
    }

    private onFromChange(items: IPersonaProps[]): void {
        this.setState({ from: items });
        this.props.onFromChange(items);
    }

    private onToChange(items: IPersonaProps[]): void {
        this.setState({ to: items });
        this.props.onToChange(items);
    }

    private onCcChange(items: IPersonaProps[]): void {
        this.setState({ cc: items });
        if (this.props.onCcChange) {
            this.props.onCcChange(items);
        }
    }

    private onShowCc(): void {
        this.setState({ showCcField: true });
    }

    private addOrRemoveExpandableClass(): void {
        const toEmailField = document.querySelector(".newforma-toPicker");
        if (this.state.to.length >= 3) {
            toEmailField?.classList.add("expandable");
            toEmailField?.classList.remove("notExpandable");
        } else {
            toEmailField?.classList.add("notExpandable");
            toEmailField?.classList.remove("expandable");
        }

        const ccEmailField = document.querySelector(".newforma-ccPicker");
        if (this.state.cc.length >= 3) {
            ccEmailField?.classList.add("expandable");
            ccEmailField?.classList.remove("notExpandable");
        } else {
            ccEmailField?.classList.add("notExpandable");
            ccEmailField?.classList.remove("expandable");
        }
    }

    private getFieldForForwardSubmittal(field: "to" | "cc"): IPersonaProps[] {
        return this.props.EmailRecipients && _.size(this.props.EmailRecipients[field])
            ? this.props.EmailRecipients[field] || []
            : [];
    }

    private renderCcField(): JSX.Element {
        return (
            <>
                <div className="newforma-ccContainer" hidden={!this.state.showCcField}>
                    <AssigneeComponent
                        className="newforma-ccPicker"
                        assignees={this.props.shouldClearEmails ? [] : this.state.cc}
                        selectedProject={this.props.project}
                        disabled={this.props.disabled || this.state.isLoadingTeamMembers}
                        currentUserEmail={this.props.officeWrapper.userProfileEmailAddress}
                        onError={this.props.onError}
                        onAssigneeChanged={this.onCcChange.bind(this)}
                        formValidationHelpers={this.props.formValidationHelpers}
                        logger={this.props.logger}
                        projectsService={this.props.projectsService}
                        label={this.props.translate("SHARED.FROM_AND_TO.CC_LABEL") as string}
                        required={!!this.props.isCcRequired}
                        showAssignToMe={false}
                    />
                </div>
                {this.state.showCcField ? null : (
                    <LinkComponent
                        className="newforma-addCc"
                        text={this.props.translate("SHARED.FROM_AND_TO.ADD_CC") as string}
                        onClick={this.onShowCc.bind(this)}
                    />
                )}
            </>
        );
    }

    render(): JSX.Element {
        return (
            <div className={`${this.props.className} newforma-fromAndToComponent`}>
                {!this.props.shouldHideFrom ? (
                    <AssigneeComponent
                        className="newforma-formSpacing newforma-fromPicker"
                        assignees={this.state.from}
                        selectedProject={this.props.project}
                        disabled={this.props.disabled || this.state.isLoadingTeamMembers}
                        currentUserEmail={this.props.officeWrapper.userProfileEmailAddress}
                        onError={this.props.onError}
                        onAssigneeChanged={this.onFromChange.bind(this)}
                        formValidationHelpers={this.props.formValidationHelpers}
                        logger={this.props.logger}
                        projectsService={this.props.projectsService}
                        label={this.props.translate("SHARED.FROM_AND_TO.FROM_LABEL") as string}
                        required={this.props.isFromRequired}
                        showAssignToMe={this.props.shouldHideFrom}
                        maxAllowed={1}
                    />
                ) : null}
                <AssigneeComponent
                    className="newforma-formSpacing newforma-toPicker"
                    assignees={this.props.shouldClearEmails ? [] : this.state.to}
                    selectedProject={this.props.project}
                    disabled={this.props.disabled || this.state.isLoadingTeamMembers}
                    currentUserEmail={this.props.officeWrapper.userProfileEmailAddress}
                    onError={this.props.onError}
                    onAssigneeChanged={this.onToChange.bind(this)}
                    formValidationHelpers={this.props.formValidationHelpers}
                    logger={this.props.logger}
                    projectsService={this.props.projectsService}
                    label={this.props.translate("SHARED.FROM_AND_TO.TO_LABEL") as string}
                    required={this.props.isToRequired}
                    showAssignToMe={false}
                />
                {this.props.includeCc ? this.renderCcField() : null}
            </div>
        );
    }
}

export default withLocalize(FromAndToComponent);
