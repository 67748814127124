import { Icon, Separator, Stack } from "office-ui-fabric-react";
import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { Logger } from "../../../services/Logger";
import KeywordsDropdown from "../../shared/keywordsDropdown/KeywordsDropdown";
import TranslatedDatePickerComponent from "../../shared/translatedDatePicker/TranslatedDatePickerComponent";
import "./ForwardRfi.less";
import { ElementVisibility } from "../../../models/shared/ElementVisibility";
import FromAndToComponent from "../../shared/fromAndToComponent/FromAndToComponent";
import { OfficeWrapper, MailboxItem } from "../../../services/OfficeWrapper";
import { FormValidationHelpers } from "../../../helpers/FormValidationHelpers";
import { IProjectsService } from "../../../services/NewformaApi/IProjectsService";
import { IPersonaProps, ITag, MessageBarType, Label, TextField } from "office-ui-fabric-react";
import { DetailedKeyword as ProjectKeyword } from "../../../models/ProjectKeywordsResponse";
import { ProjectHelper } from "../../../helpers/ProjectHelper";
import { ExpiredSessionError } from "../../../models/ExpiredSessionError";
import { EmailRecipients } from "../../../models/shared/FileTransferEmailFieldsDetails";
import ReminderComponent from "../../shared/reminder/ReminderComponent";
import HTMLEditor from "../../shared/editor/Editor";
import { ConfigurationService } from "../../../services/ConfigurationService";

export interface ForwardRfiProps extends LocalizeContextProps {
    shouldShowRfi: () => void;
    updateForwardDueDate: (date: Date | undefined) => void;
    forwardDueDate: Date | undefined;
    theme: string;
    logger: Logger;
    officeWrapper: OfficeWrapper;
    formValidationHelpers: FormValidationHelpers;
    mailboxItem: MailboxItem | null;
    projectsService: IProjectsService;
    onShowToast: (message: string | null, type: MessageBarType) => void;
    selectedProject: ITag | null;
    onExpiredSession: () => void;
    updatePurposes: (option: ProjectKeyword | null, purposes: ProjectKeyword[]) => void;
    priorSelectedPurpose: ProjectKeyword | null;
    priorSelectedPurposes: ProjectKeyword[];
    updateEmails: (emails: EmailRecipients) => void;
    priorEmails: EmailRecipients;
    isFiling: boolean;
    updateRemarks: (text: string | undefined) => void;
    forwardRemarks: string | undefined;
    updateReminder: (date: number | undefined) => void;
    forwardReminder: number | undefined;
    isFileTransferAndEditorSupported: boolean;
    configService: ConfigurationService;
}

export interface ForwardRfiState {
    from: IPersonaProps[];
    to: IPersonaProps[];
    cc: IPersonaProps[];
    shouldClearEmails: boolean;
    isClearForm: boolean;
    purposes: ProjectKeyword[];
    selectedPurpose: ProjectKeyword | null;
    isLoadingKeywords: boolean;
    date: Date | undefined;
    remarks: string | undefined;
    reminderDays: number | undefined;
    dueDate: Date | undefined;
}

class ForwardRfi extends React.Component<ForwardRfiProps, ForwardRfiState> {
    defaultState: ForwardRfiState = {
        from: [],
        to: [],
        cc: [],
        shouldClearEmails: false,
        isClearForm: false,
        purposes: [],
        selectedPurpose: null,
        date: undefined,
        isLoadingKeywords: false,
        remarks: "",
        reminderDays: undefined,
        dueDate: undefined,
    };

    reminderMaxValue = 365;

    constructor(props: ForwardRfiProps) {
        super(props);

        this.state = this.defaultState;
    }

    async componentDidMount() {
        this.props.logger.info("Forward rfi component mounted");
        if (this.props.selectedProject && !this.props.priorSelectedPurpose) {
            await this.getKeywords(this.props.selectedProject.key, this.props.selectedProject.name);
        } else if (this.props.priorSelectedPurpose && this.props.priorSelectedPurposes) {
            this.setState({
                selectedPurpose: this.props.priorSelectedPurpose,
                purposes: this.props.priorSelectedPurposes,
            });
        }
        if (this.props.priorEmails) {
            this.setState({
                to: this.props.priorEmails.to,
                cc: this.props.priorEmails.cc ? this.props.priorEmails.cc : [],
            });
        }
        if (this.props.forwardDueDate) {
            this.setState({ date: this.props.forwardDueDate });
        }
        if (this.props.forwardRemarks) {
            this.setState({ remarks: this.props.forwardRemarks });
        }
        if (this.props.forwardReminder) {
            this.setState({ reminderDays: this.props.forwardReminder });
        }
    }

    async componentDidUpdate(prevProps: Readonly<ForwardRfiProps>, prevState: Readonly<ForwardRfiState>) {
        if (this.props.selectedProject && this.props.selectedProject !== prevProps.selectedProject) {
            await this.getKeywords(this.props.selectedProject.key, this.props.selectedProject.name);
        }
    }

    private onDateChange(date: Date | undefined): void {
        this.setState({ date: date });
        this.props.updateForwardDueDate(date);
    }

    private onToChange(items?: IPersonaProps[]): void {
        this.setState({
            to: items || [],
            shouldClearEmails: false,
        });
    }

    private onCcChange(people: IPersonaProps[]): void {
        this.setState({
            cc: people || [],
            shouldClearEmails: false,
        });
    }

    private onPurposeSelectionChange(option: ProjectKeyword | null): void {
        this.setState({ selectedPurpose: option });
        this.props.updatePurposes(option, this.state.purposes);
    }

    private async getKeywords(projectNrn: string, projectName: string): Promise<void> {
        this.props.logger.info(`Loading keywords in forward rfi for selected project: ${projectName}`);
        this.setState({ isLoadingKeywords: true });

        try {
            const purposesResponse = await this.props.projectsService.getProjectSubmittalPurposesKeywords(projectNrn);

            const purposes = ProjectHelper.sortKeywords(purposesResponse);

            this.setState({
                purposes: purposes,
            });
        } catch (error) {
            if (ExpiredSessionError.isInstanceOf(error)) {
                this.props.onExpiredSession();
                return;
            }

            this.props.logger.error("ForwardRfiComponent Error loading keywords", error);

            this.clearKeywords();
            this.props.onShowToast(
                this.props.translate("RFI.ERRORS_LOADING_KEYWORDS_FAILED") as string,
                MessageBarType.severeWarning
            );
        } finally {
            this.setState({ isLoadingKeywords: false });
        }
    }

    private clearKeywords(): void {
        this.setState({
            purposes: [],
            selectedPurpose: null,
        });
    }

    private onBackButtonClicked(): void {
        this.props.shouldShowRfi();
        this.props.updateEmails({ to: this.state.to, cc: this.state.cc });
        this.props.updateRemarks(this.state.remarks);
    }

    private onFromChange(people: IPersonaProps[]): void {
        this.setState({ from: people });
    }

    private onReminderChange(value: number | undefined): void {
        this.setState({ reminderDays: value });
        this.props.updateReminder(value);
    }

    private onOldEditorRemarksChange(
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ): void {
        this.setState({ remarks: newValue || "" });
        this.props.updateRemarks(newValue);
    }

    private onRemarksChange(newValue?: string): void {
        this.setState({ remarks: newValue || "" });
        this.props.updateRemarks(newValue);
    }

    render(): JSX.Element {
        return (
            <div className="newforma-forwardRfiContainer margin-bottom">
                <div className="headerText">
                    <Stack className="headerText">
                        <Separator className="custom-separator-bg" alignContent="start">
                            <div className="headerText">{this.props.translate("RFI.FORWARD_RFI.HEADER_TEXT")}</div>
                        </Separator>
                    </Stack>
                </div>
                <div
                    className="newforma-forwardRfiBackContainer"
                    onClick={() => this.onBackButtonClicked()}
                    data-theme={this.props.theme}
                >
                    <Icon iconName="NavigateBack" className="backIcon" data-theme={this.props.theme} />
                    <span className="rfiText">{this.props.translate("RFI.FORWARD_RFI.BACK_BUTTON")}</span>
                </div>
                <TranslatedDatePickerComponent
                    date={this.props.forwardDueDate}
                    label={this.props.translate("RFI.FORWARD_RFI.DUE_BACK_DATE") as string}
                    disabled={false}
                    onDateChange={this.onDateChange.bind(this)}
                    className="newforma-aiSpacing"
                    required={true}
                    clearDateButtonVisibility={ElementVisibility.None}
                />
                <ReminderComponent
                    onChange={this.onReminderChange.bind(this)}
                    className="newforma-formSpacing newforma-reminderFont"
                    max={this.reminderMaxValue}
                    disabled={false}
                    value={this.props.forwardReminder}
                    isFiling={false} // change this
                />
                <FromAndToComponent
                    className={"newforma-formSpacing"}
                    isFromRequired={false}
                    isToRequired={true}
                    isCcRequired={false}
                    includeCc={true}
                    officeWrapper={this.props.officeWrapper}
                    disabled={false} // change this
                    formValidationHelpers={this.props.formValidationHelpers}
                    mailboxItem={this.props.mailboxItem}
                    onFromChange={this.onFromChange.bind(this)}
                    onToChange={this.onToChange.bind(this)}
                    onCcChange={this.onCcChange.bind(this)}
                    logger={this.props.logger}
                    projectsService={this.props.projectsService}
                    onError={this.props.onShowToast}
                    project={this.props.selectedProject}
                    shouldHideFrom={true}
                    shouldClearEmails={this.state.shouldClearEmails || this.state.isClearForm}
                    EmailRecipients={this.props.priorEmails}
                    isTransferFailed={true}
                    isForwardRfiInProgress={true}
                />
                <KeywordsDropdown
                    className="newforma-forwardRfiPurposeDropdown"
                    id="purposePicker"
                    options={this.state.purposes}
                    label={this.props.translate("RFI.PURPOSE.LABEL") as string}
                    placeholder={this.props.translate("RFI.PURPOSE.PLACEHOLDER") as string}
                    disabled={!this.state.purposes.length || this.props.isFiling}
                    isLoading={this.state.isLoadingKeywords}
                    isProjectSelected={true}
                    required={true}
                    onSelectionChange={this.onPurposeSelectionChange.bind(this)}
                    selectFirstOption={false}
                    theme={this.props.theme}
                    selectedPurposeKey={this.state.selectedPurpose ? this.state.selectedPurpose.displayOrder : null}
                />
                <Label>{this.props.translate("RFI.FORWARD_RFI.REMARKS") as string}</Label>
                {this.props.isFileTransferAndEditorSupported ? (
                    <HTMLEditor
                        value={this.state.remarks}
                        onRemarksUpdate={this.onRemarksChange.bind(this)}
                        configService={this.props.configService}
                        isFiling={this.props.isFiling}
                    />
                ) : (
                    <TextField
                        className="newforma-submittalSpacing"
                        id="newforma-forwardSubmittalDescription"
                        multiline
                        resizable={true}
                        value={this.state.remarks}
                        onChange={this.onOldEditorRemarksChange.bind(this)}
                        disabled={false} // change this in the story for updating when components are disabled {this.state.isFiling || this.state.isLoading}
                        rows={5}
                        maxLength={65000}
                    />
                )}
            </div>
        );
    }
}

export default withLocalize(ForwardRfi);
