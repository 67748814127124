import * as React from "react";
import "./RfiComponent.less";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { AppPage } from "../shared/navigationHeader/NavigationHeaderComponent";
import { ConfigurationsApiService } from "../../services/NewformaApi/ConfigurationsApiService";
import SuggestedProjectPickerComponent from "../shared/suggestedProjectPicker/SuggestedProjectPickerComponent";
import {
    Checkbox,
    DefaultButton,
    Dropdown,
    IDropdownOption,
    IPersonaProps,
    ITag,
    Label,
    MessageBarType,
    ProgressIndicator,
    ResponsiveMode,
    TextField,
    getTheme,
} from "office-ui-fabric-react";
import { MailboxItem, OfficeWrapper } from "../../services/OfficeWrapper";
import { Logger } from "../../services/Logger";
import { SmartFilingManager } from "../../services/SmartFiling/SmartFilingManager";
import SpecSectionComponent from "../shared/specSection/SpecSectionComponent";
import { IProjectsService } from "../../services/NewformaApi/IProjectsService";
import { DetailedKeyword as ProjectKeyword, KeywordListType } from "../../models/ProjectKeywordsResponse";
import BoundDateRangePickerComponent from "../shared/boundDateRangePickers/BoundDateRangePickerComponent";
import ChipPickerComponent from "../shared/chipPicker/ChipPickerComponent";
import { FormValidationHelpers } from "../../helpers/FormValidationHelpers";
import { DateHelpers } from "../../helpers/DateHelpers";
import { ExpiredSessionError } from "../../models/ExpiredSessionError";
import { ApiRequestErrorLevel } from "../../models/ApiRequestErrorWithMessage";
import { RfiApiService } from "../../services/NewformaApi/RfiApiService";
import { LogRfiParams } from "../../models/workflow/rfi/LogRfiParams";
import { Keyword } from "../../models/Keyword";
import FromAndToComponent from "../shared/fromAndToComponent/FromAndToComponent";
import AttachmentsComponent from "../shared/attachments/AttachmentsComponent";
import { AnalyticsManager } from "../../services/AnalyticsManager";
import { NrnServiceWrapper } from "../../services/NrnServiceWrapper";
import ReminderComponent from "../shared/reminder/ReminderComponent";
import KeywordsDropdown from "../shared/keywordsDropdown/KeywordsDropdown";
import { DateOffsetType, NumberEntry } from "../../models/projects/ProjectSettingsResponse";
import { WorkflowActionType } from "../../models/workflow/WorkflowActionType";
import { MsGraphApiService } from "../../services/MsGraphApiService";
import { ContractsResponse } from "../../models/projects/ContractsResponse";
import { ProjectHelper } from "../../helpers/ProjectHelper";
import ForwardCheckbox from "../shared/checkbox/forwardCheckbox";
import ForwardRfi from "./forwardRfi/ForwardRfi";
import { ProjectsCacheKeys } from "../../models/StorageKeys";
import { InvalidateCacheService } from "../../services/NewformaApi/InvalidateCacheService";
import { AttachmentItem } from "../../models/shared/AttachmentList";
import HTMLEditor from "../shared/editor/Editor";
import { ConfigurationService } from "../../services/ConfigurationService";
import { AttachmentDataHelpers } from "../../helpers/AttachmentDataHelpers";
import { EmailRecipients } from "../../models/shared/FileTransferEmailFieldsDetails";
import { DetailedKeyword } from "../../models/ProjectKeywordsResponse";

export interface RfiComponentProps extends LocalizeContextProps {
    configurationsApiService: ConfigurationsApiService;
    logger: Logger;
    smartFilingManager: SmartFilingManager;
    onExpiredSession: () => void;
    projectsService: IProjectsService;
    formValidationHelpers: FormValidationHelpers;
    officeWrapper: OfficeWrapper;
    dateHelpers: DateHelpers;
    rfiApiService: RfiApiService;
    onShowToast: (toastMessage: string | null, toastType: MessageBarType) => void;
    onSetNavigationPage: (page: AppPage) => void;
    mailboxItem: MailboxItem | null;
    analyticsManager: AnalyticsManager;
    nrnServiceWrapper: NrnServiceWrapper;
    msGraphApiService: MsGraphApiService;
    theme: string;
    invalidateCacheService: InvalidateCacheService;
    isFilePathInAttachmentsSupported?: boolean;
    isFileTransferAndEditorSupported: boolean;
    configService: ConfigurationService;
    isForwardRfiSupported: boolean;
}

export interface RfiComponentState {
    projects: ITag[];
    isCloudProject: boolean;
    selectedProject: ITag | null;
    isLoadingProjects: boolean;
    isFiling: boolean;
    subject: string;
    number: string | undefined;
    specSection: string;
    receivedDate: Date | undefined;
    dueDate: Date | undefined;
    urgent: boolean;
    types: ProjectKeyword[];
    selectedType: Keyword | null;
    isLoadingKeywords: boolean;
    senderNumber: string;
    from: IPersonaProps[];
    to: IPersonaProps[];
    question: string;
    suggestions: string;
    internalNotes: string;
    impactSchedules: ProjectKeyword[];
    impactCosts: ProjectKeyword[];
    selectedSchedule: ProjectKeyword | null;
    selectedCost: ProjectKeyword | null;
    selectedReasons: IPersonaProps[];
    selectedDisciplines: IPersonaProps[];
    selectedKeywords: IPersonaProps[];
    attachments: AttachmentItem[];
    fileUploadIsInProgress: boolean;
    failedUploadAttachmentNames: string[];
    reminderDays: number | undefined;
    contracts: IDropdownOption[];
    selectedContractKey: number | null;
    purposes: ProjectKeyword[];
    selectedPurpose: ProjectKeyword | null;
    numberEntryMode: NumberEntry | null;
    shouldForwardRfi: boolean;
    shouldShowForwardRfiOptions?: boolean;
    isForwardFormValid: boolean;
    forwardRfiSelectedPurpose: DetailedKeyword | null;
    forwardRfiPurposes: ProjectKeyword[];
    forwardEmailRecipients: EmailRecipients;
    forwardDueDate: Date | undefined;
    forwardRemarks: string | undefined;
    forwardReminderDays: number | undefined;
}

export const defaultRfiComponentState: RfiComponentState = {
    projects: [],
    isCloudProject: false,
    selectedProject: null,
    isLoadingProjects: true,
    isFiling: false,
    subject: "",
    number: "",
    specSection: "",
    receivedDate: undefined,
    dueDate: undefined,
    urgent: false,
    types: [],
    selectedType: null,
    isLoadingKeywords: true,
    senderNumber: "",
    from: [],
    to: [],
    question: "",
    suggestions: "",
    internalNotes: "",
    impactCosts: [],
    impactSchedules: [],
    selectedCost: null,
    selectedSchedule: null,
    selectedDisciplines: [],
    selectedReasons: [],
    selectedKeywords: [],
    attachments: [],
    fileUploadIsInProgress: false,
    failedUploadAttachmentNames: [],
    reminderDays: undefined,
    contracts: [],
    selectedContractKey: null,
    purposes: [],
    selectedPurpose: null,
    numberEntryMode: null,
    shouldForwardRfi: false,
    shouldShowForwardRfiOptions: false,
    isForwardFormValid: false,
    forwardDueDate: undefined,
    forwardRfiSelectedPurpose: null,
    forwardRfiPurposes: [],
    forwardEmailRecipients: {
        to: [],
        cc: [],
    },
    forwardRemarks: undefined,
    forwardReminderDays: undefined,
};

class RfiComponent extends React.Component<RfiComponentProps, RfiComponentState> {
    reminderMaxValue = 365;

    constructor(props: RfiComponentProps, context: RfiComponentState) {
        super(props, context);

        this.state = defaultRfiComponentState;
    }

    async componentDidMount(): Promise<void> {
        this.props.logger.info("RfiComponent mounted");
        this.props.onSetNavigationPage(AppPage.FileAsRfi);
        await Promise.all([this.populateDefaults(), this.loadProjects()]);
    }

    async componentDidUpdate(
        prevProps: Readonly<RfiComponentProps>,
        prevState: Readonly<RfiComponentState>,
        snapshot?: any
    ) {
        if (this.props.mailboxItem && prevProps.mailboxItem !== this.props.mailboxItem) {
            this.setState((state) => ({
                ...defaultRfiComponentState,
                projects: state.projects,
                selectedProject: state.selectedProject,
                attachments: [],
            }));

            await this.populateDefaults();
        }
    }

    private async loadProjects(): Promise<void> {
        this.setState({ isLoadingProjects: true });
        try {
            const projectsResponse = await this.props.projectsService.getProjectsSupportingRfis();
            const projectsITag: ITag[] = projectsResponse.projects.map((project) => {
                const projectDisplay = project.number ? `${project.number} - ${project.name}` : project.name;
                return { key: project.nrn, name: projectDisplay };
            });
            this.setState({ projects: projectsITag });
        } catch (error) {
            if (ExpiredSessionError.isInstanceOf(error)) {
                this.props.onExpiredSession();
                return;
            }
            this.setState({
                projects: [],
            });
            this.props.onShowToast(
                this.props.translate("RFI.ERRORS.LOADING_PROJECTS_FAILED") as string,
                MessageBarType.severeWarning
            );
        } finally {
            this.setState({ isLoadingProjects: false });
        }
    }

    private async loadKeywords(project: ITag): Promise<void> {
        this.setState({ isLoadingKeywords: true });
        this.props.logger.info(`Loading keywords for selected project: ${project.name}`);
        try {
            const isCloudProject = this.props.nrnServiceWrapper.isCloudProject(project?.key as string);
            if (isCloudProject) {
                const [typesResponse, impactScheduleResponse, impactCostResponse] = await Promise.all([
                    this.props.projectsService.getProjectKeywords(project.key, KeywordListType.RfiTypes),
                    this.props.projectsService.getProjectKeywords(project.key, KeywordListType.RfiImpactSchedule),
                    this.props.projectsService.getProjectKeywords(project.key, KeywordListType.RfiImpactCost),
                ]);
                this.setState({
                    types: ProjectHelper.sortKeywords(typesResponse),
                    impactCosts: ProjectHelper.sortKeywords(impactCostResponse),
                    impactSchedules: ProjectHelper.sortKeywords(impactScheduleResponse),
                });
            } else {
                const [typesResponse, purposesResponse, contractsResponse] = await Promise.all([
                    this.props.projectsService.getProjectKeywords(project.key, KeywordListType.RfiTypes),
                    this.props.rfiApiService.getRfiActionKeywords(project.key, WorkflowActionType.Log),
                    this.getContracts(project.key),
                ]);
                const sortedContracts = ProjectHelper.sortContracts(contractsResponse);
                const contractsWithNoneAction = [
                    {
                        subject: this.props.translate("SHARED.KEYWORD_DROPDOWN.NONE") as string,
                        nrn: "",
                    },
                    ...sortedContracts,
                ];
                const contractsDropdownOptions: IDropdownOption[] = contractsWithNoneAction.map((contract, index) => ({
                    text: contract.subject,
                    key: index,
                    data: contract,
                }));
                const purposes = ProjectHelper.sortKeywords(purposesResponse);
                this.setState({
                    types: ProjectHelper.sortKeywords(typesResponse),
                    contracts: contractsDropdownOptions,
                    purposes: purposes,
                });

                if (purposes && purposes.length > 0) {
                    this.setState({
                        selectedPurpose: purposes[0],
                    });
                }
            }
        } catch (error) {
            this.clearKeywords();

            this.handleKeywordError(error);
        } finally {
            this.setState({ isLoadingKeywords: false });
        }
    }

    private async getContracts(projectNrn: string): Promise<ContractsResponse> {
        try {
            return await this.props.projectsService.getContracts(projectNrn);
        } catch {
            return Promise.resolve({
                items: [],
                offsetToken: undefined,
            });
        }
    }

    private handleKeywordError(error: any): void {
        if (ExpiredSessionError.isInstanceOf(error)) {
            this.props.onExpiredSession();
            return;
        }

        this.props.onShowToast(
            this.props.translate("RFI.ERRORS.LOADING_KEYWORDS_FAILED") as string,
            MessageBarType.severeWarning
        );
    }

    private async populateDefaults(): Promise<void> {
        const attachments = this.props.officeWrapper.getCurrentEmailAttachmentDetails();
        const attachmentsToUse = attachments.filter((attachment) => !attachment.isInline);
        const attachmentsNoCloud = attachmentsToUse.filter(
            (attachment) => attachment.attachmentType !== Office.MailboxEnums.AttachmentType.Cloud
        );
        const question = await this.props.msGraphApiService.getCurrentClearMessageBody(
            this.props.isFileTransferAndEditorSupported
        );
        const subject = await this.props.officeWrapper.getCurrentEmailSubject();
        const from = this.props.officeWrapper.getSenderEmailAddress();
        const to = this.props.officeWrapper
            .getCurrentEmailToRecipients()
            .map((recipient) => recipient.emailAddress)
            .filter((email) => !email.toLowerCase().endsWith("newforma.email"))
            .map((email) => ({ text: email }));

        const receivedDate = this.props.officeWrapper.getCurrentEmailDate();
        const dueDate = this.props.dateHelpers.getDateWithOffset(receivedDate, 14);
        const attachmentDetails = await this.props.msGraphApiService.getAttachmentDetails();
        const htmlBody = AttachmentDataHelpers.replaceCIDReferences(question, attachmentDetails);

        this.setState((state) => ({
            ...defaultRfiComponentState,
            selectedProject: state.selectedProject,
            projects: state.projects,
            question: this.props.isFileTransferAndEditorSupported ? htmlBody.trim() : question.trim(),
            subject: subject.trim(),
            from: from ? [{ text: from }] : [],
            to,
            receivedDate,
            dueDate,
            isLoadingProjects: false,
            isLoadingKeywords: false,
            attachments: attachmentsNoCloud,
        }));
    }

    private async onProjectSelected(selectedProject: ITag | null): Promise<void> {
        this.clearKeywords();

        const isCloudProject = this.props.nrnServiceWrapper.isCloudProject(selectedProject?.key as string);
        this.setState({ selectedProject, isCloudProject });
        this.toggleProjectTeamVisibility(isCloudProject);

        if (selectedProject) {
            await Promise.all([this.loadKeywords(selectedProject), this.getProjectSettings(selectedProject)]);
        }
    }

    private toggleProjectTeamVisibility(cloudProject: boolean) {
        const teamMemberDiv = document.querySelector(
            ".ms-CommandBar-secondaryCommand .ms-OverflowSet-item:nth-child(2)"
        );
        cloudProject
            ? teamMemberDiv?.classList.remove("hideProjectTeam")
            : teamMemberDiv?.classList.add("hideProjectTeam");
    }

    private clearKeywords(): void {
        this.setState({
            types: [],
            selectedType: null,
            impactSchedules: [],
            impactCosts: [],
            selectedCost: null,
            selectedSchedule: null,
            selectedDisciplines: [],
            selectedReasons: [],
            selectedKeywords: [],
            selectedContractKey: null,
            contracts: [],
            purposes: [],
            specSection: "",
        });
    }

    private async getProjectSettings(project: ITag) {
        try {
            const projectSettings = await this.props.projectsService.getProjectSettings(project.key as string);
            if (!projectSettings) {
                this.setState({
                    numberEntryMode: null,
                    dueDate: this.props.dateHelpers.getDateWithOffset(this.state.receivedDate as Date, 14),
                });
                return;
            }
            this.setState({
                numberEntryMode: projectSettings.rfis.numberEntryMode,
                dueDate: this.getDueDate(
                    projectSettings.rfis.dueDateOffsetInDays,
                    projectSettings.rfis.dueDateOffsetType
                ),
            });
        } catch (error) {
            this.handleApiError(error, this.props.translate("SHARED.ERRORS.FAILED_LOADING_PROJECT_SETTINGS") as string);
        }
    }

    private getDueDate(dateOffset: number | undefined, offsetType: DateOffsetType): Date | undefined {
        if (!this.state.receivedDate) {
            return undefined;
        }

        const offset = dateOffset === undefined ? 14 : dateOffset;
        return offsetType === DateOffsetType.business
            ? this.props.dateHelpers.addBusinessDays(this.state.receivedDate, offset)
            : this.props.dateHelpers.getDateWithOffset(this.state.receivedDate, offset);
    }

    private onSubjectChange(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void {
        this.setState({ subject: newValue || "" });
    }

    private onNumberChange(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void {
        this.setState({ number: newValue });
    }

    private onSpecSectionChange(
        specSectionName: string,
        itemNumber: string,
        revisionNumber: string,
        isRevisionNumberEnabled: boolean
    ): void {
        this.setState({ specSection: specSectionName });
    }

    private onSpecSectionError(error: any): void {
        this.props.onShowToast(
            this.props.translate("RFI.ERRORS.SPEC_SECTION_RETRIEVAL_FAILED") as string,
            MessageBarType.severeWarning
        );
    }

    private onReceivedDateChanged(date: Date | undefined): void {
        this.setState({ receivedDate: date });
    }

    private onDueDateChanged(date: Date | undefined): void {
        this.setState({ dueDate: date });
    }

    private onUrgentCheckboxChange(event?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void {
        this.setState({ urgent: !!checked });
    }

    private onTypeSelectionChange(option: Keyword | null): void {
        this.setState({ selectedType: option });
    }

    private onContractSelectionChange(
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
        index?: number
    ): void {
        if (!index) {
            this.setState({ selectedContractKey: null });
            return;
        }

        this.setState({ selectedContractKey: index });
    }

    private onPurposeSelectionChange(option: ProjectKeyword | null): void {
        this.setState({ selectedPurpose: option });
    }

    private onSenderNumberChanged(
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ): void {
        this.setState({ senderNumber: newValue || "" });
    }

    private onFromChange(items: IPersonaProps[]): void {
        this.setState({ from: items });
    }

    private onToChange(items: IPersonaProps[]): void {
        this.setState({ to: items });
    }

    private onQuestionChange(newValue?: string): void {
        this.setState({ question: newValue || "" });
    }

    private onOldEditorQuestionChange(
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ): void {
        this.setState({ question: newValue || "" });
    }

    private onSuggestionsChange(
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ): void {
        this.setState({ suggestions: newValue || "" });
    }

    private onInternalNotesChange(
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ): void {
        this.setState({ internalNotes: newValue || "" });
    }

    private onImpactScheduleChange(option: ProjectKeyword | null): void {
        this.setState({ selectedSchedule: option });
    }

    private onImpactCostChange(option: ProjectKeyword | null): void {
        this.setState({ selectedCost: option });
    }

    private onReasonsChange(items: IPersonaProps[]): void {
        this.setState({ selectedReasons: items });
    }

    private onDisciplinesChange(items: IPersonaProps[]): void {
        this.setState({ selectedDisciplines: items });
    }

    private onKeywordsChange(items: IPersonaProps[]): void {
        this.setState({ selectedKeywords: items });
    }

    private async onFormSubmit(): Promise<void> {
        this.props.logger.info("file email as rfi form submitted");
        this.setState({ isFiling: true });

        try {
            const messageNrn = this.props.officeWrapper.getCurrentMessageNrn();
            const selectedPurpose = this.state.selectedPurpose as Keyword;
            const rfiParams: LogRfiParams = {
                subject: (this.state.subject as string).trim(),
                receivedDate: (this.state.receivedDate as Date).toISOString(),
                dueDate: (this.state.dueDate as Date).toISOString(),
                from: this.props.formValidationHelpers.mapPersonaPropsToContact(this.state.from)[0],
                to: this.props.formValidationHelpers.mapPersonaPropsToContact(this.state.to),
                question: this.state.question.trim(),
                specSection: this.state.specSection ? { name: this.state.specSection, type: "generic" } : undefined,
                number: this.state.number?.trim() || undefined,
                urgent: !this.state.isCloudProject ? undefined : this.state.urgent,
                senderNumber: this.state.senderNumber.trim() || undefined,
                suggestion: this.state.suggestions.trim() || undefined,
                internalNotes: this.state.internalNotes.trim() || undefined,
                types: !!this.state.selectedType ? [this.state.selectedType] : undefined,
                impactSchedule: this.state.selectedSchedule || undefined,
                impactCost: this.state.selectedCost || undefined,
                reasons: this.props.formValidationHelpers.mapPersonaPropsToKeywords(this.state.selectedReasons),
                disciplines: this.props.formValidationHelpers.mapPersonaPropsToKeywords(this.state.selectedDisciplines),
                keywords: this.props.formValidationHelpers.mapPersonaPropsToKeywords(this.state.selectedKeywords),
                via: this.state.isCloudProject ? undefined : { name: "Email", type: "transferMethod" },
                purpose: this.state.isCloudProject
                    ? undefined
                    : {
                          name: selectedPurpose.name,
                          type: selectedPurpose.type,
                      },
                reminder: !!this.state.dueDate && !this.state.isCloudProject ? this.state.reminderDays : undefined,
                contract: this.state.selectedContractKey
                    ? this.state.contracts[this.state.selectedContractKey].data.contractNrn
                    : undefined,
            };
            await this.props.rfiApiService.fileEmailAsRfi(
                this.state.selectedProject?.key as string,
                rfiParams,
                messageNrn,
                this.state.attachments,
                this.fileUploadCallback.bind(this),
                this.state.isCloudProject,
                this.props.isFileTransferAndEditorSupported
            );

            try {
                const projectAsAny = this.state.selectedProject as any;
                this.props.analyticsManager.recordSmartFilingEvents(
                    projectAsAny.suggestedProject,
                    projectAsAny.suggestionIndex
                );
                await this.props.smartFilingManager.addToFiledHistory(
                    this.state.selectedProject ?? { key: "", name: "" },
                    this.props.mailboxItem?.conversationId as string,
                    this.props.mailboxItem?.sender.emailAddress as string
                );
            } catch (error) {
                this.props.logger.error("RfiComponent - failed to update smart filing history", error);
            }

            this.props.onShowToast(this.props.translate("RFI.SUCCESS_MESSAGE") as string, MessageBarType.success);
            this.setState({
                number: "",
                senderNumber: "",
                suggestions: "",
                internalNotes: "",
                selectedReasons: [],
                selectedDisciplines: [],
                selectedKeywords: [],
                selectedContractKey: null,
            });
        } catch (error) {
            this.handleApiError(error, this.props.translate("RFI.ERRORS.LOG_RFI_FAILED_GENERIC") as string);
        } finally {
            this.setState({
                isFiling: false,
            });
        }
    }

    private handleApiError(error: any, displayMessage: string): void {
        this.props.logger.error(`RFIComponent API error: ${displayMessage}`, error);

        if (ExpiredSessionError.isInstanceOf(error)) {
            this.props.onExpiredSession();
            return;
        }

        if (error.status === 405) {
            this.props.onShowToast(
                this.props.translate("RFI.ERRORS.PERMISSIONS_INSUFFICIENT") as string,
                MessageBarType.severeWarning
            );
            return;
        }

        if (error.level !== undefined && error.level === ApiRequestErrorLevel.ERROR) {
            const failedAttachmentNames = this.state.failedUploadAttachmentNames;
            if (failedAttachmentNames.length) {
                const messageToDisplay = (this.props.translate(error.messageToDisplay) as string)
                    .replace("[[attachment-names]]", failedAttachmentNames.join("\n"))
                    .replace("[[failed-attachment-count]]", failedAttachmentNames.length.toString());
                this.props.onShowToast(messageToDisplay, MessageBarType.severeWarning);
                return;
            }
            this.props.onShowToast(
                this.props.translate(error.messageToDisplay) as string,
                MessageBarType.severeWarning
            );
            return;
        }

        if (error.level === undefined) {
            this.props.onShowToast(displayMessage, MessageBarType.severeWarning);
        }
    }

    private isFormValid(): boolean {
        const state = this.state;
        return (
            !!state.selectedProject &&
            !!state.subject &&
            !!state.receivedDate &&
            (this.state.isCloudProject ? !!this.state.dueDate : true) && // due date not required for npc projects
            !!state.from.length &&
            !!state.to.length &&
            !!state.question &&
            (this.state.isCloudProject ? true : !!state.selectedPurpose) &&
            this.props.formValidationHelpers.areAssigneesValid(this.state.from, true) &&
            this.props.formValidationHelpers.areAssigneesValid(this.state.to, true) &&
            !!state.selectedType &&
            (!this.state.isCloudProject && this.state.numberEntryMode === NumberEntry.manual
                ? !!this.state.number
                : true) &&
            (!this.state.shouldShowForwardRfiOptions || this.state.isForwardFormValid)
        );
    }

    fileUploadCallback(isInProgress: boolean, failedIds: string[]): void {
        const failedAttachmentNames = !isInProgress ? this.getFailedAttachmentNames(failedIds) : [];
        this.setState({
            fileUploadIsInProgress: isInProgress,
            failedUploadAttachmentNames: failedAttachmentNames,
        });
    }

    private getFailedAttachmentNames(failedIds: string[]): string[] {
        return failedIds.length > 0
            ? this.state.attachments.filter((x) => failedIds.includes(x.id)).map((x) => x.name)
            : [];
    }

    private onAttachmentsUpdated(files: AttachmentItem[]): void {
        this.setState({
            attachments: files,
        });
        document.querySelector(".newforma-rfiComponent")?.scrollTo({
            top: document.querySelector(".newforma-rfiComponent")?.scrollHeight,
            behavior: "auto",
        });
    }

    private onReminderChange(value: number | undefined): void {
        this.setState({ reminderDays: value });
    }

    private getContractsPlaceholder(): string {
        if (this.state.isLoadingKeywords) {
            return this.props.translate("SHARED.KEYWORD_DROPDOWN.LOADING") as string;
        }

        if (!this.state.selectedProject) {
            return this.props.translate("SHARED.KEYWORD_DROPDOWN.NO_PROJECT_SELECTED") as string;
        }

        if (!this.state.contracts.length) {
            return this.props.translate("SHARED.KEYWORD_DROPDOWN.NO_ITEMS") as string;
        }

        return this.props.translate("RFI.CONTRACT.PLACEHOLDER") as string;
    }

    private async refreshProjects(): Promise<void> {
        await this.props.invalidateCacheService.invalidateCache(ProjectsCacheKeys.rfisCacheName);
        return this.loadProjects();
    }

    moveToForwardRfi() {
        this.setState({
            shouldShowForwardRfiOptions: true,
        });
        this.props.logger.info("Changing to forward RFI view");
    }

    shouldGoBackToRfi() {
        this.setState({ shouldShowForwardRfiOptions: false });
        this.props.logger.info("Changing to RFI view");
    }

    updateForwardRfiCheckbox() {
        this.setState({ shouldForwardRfi: !this.state.shouldForwardRfi });
    }

    updateForwardDueDate(date: Date | undefined): void {
        this.setState({ forwardDueDate: date });
    }

    updateForwardPurposes(option: ProjectKeyword | null, purposes: ProjectKeyword[]): void {
        this.setState({ forwardRfiSelectedPurpose: option, forwardRfiPurposes: purposes });
    }

    updateForwardEmailRecipients(emails: EmailRecipients): void {
        this.setState({ forwardEmailRecipients: emails });
    }

    updateForwardRemarks(remarks: string | undefined): void {
        this.setState({ forwardRemarks: remarks });
    }

    updateForwardReminder(days: number | undefined): void {
        this.setState({ forwardReminderDays: days });
    }

    render(): JSX.Element {
        const theme = getTheme();
        return (
            <div className="newforma-rfiComponent">
                <div className="newforma-rfiComponentForm">
                    {this.state.shouldShowForwardRfiOptions ? (
                        <ForwardRfi
                            shouldShowRfi={this.shouldGoBackToRfi.bind(this)}
                            theme={this.props.theme}
                            logger={this.props.logger}
                            updateForwardDueDate={this.updateForwardDueDate.bind(this)}
                            forwardDueDate={this.state.forwardDueDate}
                            officeWrapper={this.props.officeWrapper}
                            formValidationHelpers={this.props.formValidationHelpers}
                            projectsService={this.props.projectsService}
                            onShowToast={this.props.onShowToast}
                            mailboxItem={this.props.mailboxItem}
                            selectedProject={this.state.selectedProject}
                            onExpiredSession={this.props.onExpiredSession}
                            updatePurposes={this.updateForwardPurposes.bind(this)}
                            priorSelectedPurpose={this.state.forwardRfiSelectedPurpose}
                            priorSelectedPurposes={this.state.forwardRfiPurposes}
                            updateEmails={this.updateForwardEmailRecipients.bind(this)}
                            priorEmails={this.state.forwardEmailRecipients}
                            isFiling={this.state.isFiling}
                            updateRemarks={this.updateForwardRemarks.bind(this)}
                            forwardRemarks={this.state.forwardRemarks}
                            updateReminder={this.updateForwardReminder.bind(this)}
                            forwardReminder={this.state.forwardReminderDays}
                            isFileTransferAndEditorSupported={this.props.isFileTransferAndEditorSupported}
                            configService={this.props.configService}
                        />
                    ) : (
                        <>
                            <SuggestedProjectPickerComponent
                                logger={this.props.logger}
                                className="newforma-formSpacing"
                                projects={this.state.projects}
                                myProjects={this.state.projects}
                                onProjectSelected={this.onProjectSelected.bind(this)}
                                disabled={
                                    this.state.isLoadingProjects || this.state.isFiling || this.state.isLoadingKeywords
                                }
                                smartFilingManager={this.props.smartFilingManager}
                                isLoadingProjects={this.state.isLoadingProjects}
                                mailboxItem={this.props.mailboxItem}
                                onRefresh={this.refreshProjects.bind(this)}
                                theme={this.props.theme}
                            />
                            <Label required={true}>{this.props.translate("RFI.SUBJECT_LABEL") as string}</Label>
                            <TextField
                                className="newforma-formSpacing"
                                id="rfi-subject"
                                value={this.state.subject}
                                onChange={this.onSubjectChange.bind(this)}
                                disabled={this.state.isFiling || this.state.isLoadingProjects}
                                maxLength={256}
                            />
                            <div className="newforma-flexRow newforma-formSpacing">
                                {this.state.isCloudProject ? (
                                    <SpecSectionComponent
                                        className="newforma-rfiSpecSection"
                                        disabled={
                                            this.state.isFiling ||
                                            this.state.isLoadingProjects ||
                                            !this.state.selectedProject
                                        }
                                        onChange={this.onSpecSectionChange.bind(this)}
                                        onError={this.onSpecSectionError.bind(this)}
                                        projectsService={this.props.projectsService}
                                        project={this.state.selectedProject}
                                        showSpecNumber={false}
                                        keywordListType={KeywordListType.RfiSpecSection}
                                        mailboxItem={this.props.mailboxItem}
                                        required={false}
                                        formValidationHelpers={this.props.formValidationHelpers}
                                        numberEntryMode={this.state.numberEntryMode}
                                        hideRevision={!this.state.isCloudProject}
                                        clearSpecSection={false}
                                    />
                                ) : null}
                                <div
                                    className={`newforma-rfiNumber ${
                                        this.state.isCloudProject ? "newforma-halfWidth" : "newforma-fullWidth"
                                    }`}
                                >
                                    <Label
                                        required={
                                            !this.state.isCloudProject &&
                                            this.state.numberEntryMode === NumberEntry.manual
                                        }
                                    >
                                        {this.props.translate("RFI.NUMBER_LABEL") as string}
                                    </Label>
                                    <TextField
                                        id="rfi-number"
                                        value={this.state.number}
                                        onChange={this.onNumberChange.bind(this)}
                                        disabled={this.state.isFiling || this.state.isLoadingProjects}
                                        placeholder={
                                            !this.state.isCloudProject &&
                                            this.state.numberEntryMode === NumberEntry.manual
                                                ? (this.props.translate("RFI.NUMBER_MANUAL_PLACEHOLDER") as string)
                                                : (this.props.translate("RFI.NUMBER_PLACEHOLDER") as string)
                                        }
                                        maxLength={256}
                                    />
                                </div>
                            </div>
                            <BoundDateRangePickerComponent
                                className="newforma-formSpacing newforma-rfiDatePickers"
                                startDate={this.state.receivedDate}
                                endDate={this.state.dueDate}
                                startDateLabel={this.props.translate("RFI.RECEIVED_DATE_LABEL") as string}
                                endDateLabel={this.props.translate("RFI.DUE_DATE_LABEL") as string}
                                onStartDateChange={this.onReceivedDateChanged.bind(this)}
                                onEndDateChange={this.onDueDateChanged.bind(this)}
                                disabled={this.state.isFiling || this.state.isLoadingProjects}
                                startDateRequired={true}
                                endDateRequired={this.state.isCloudProject}
                                showClearDateButtonStart={false}
                                showClearDateButtonEnd={!this.state.isCloudProject}
                                isFiling={this.state.isFiling}
                            />
                            {this.state.isCloudProject ? (
                                <div className="newforma-flexRow">
                                    <div className="newforma-checkboxSpacer">&nbsp;</div>
                                    <Checkbox
                                        className="newforma-rfiUrgentCheckbox newforma-formSpacing"
                                        label={this.props.translate("RFI.URGENT") as string}
                                        checked={this.state.urgent}
                                        onChange={this.onUrgentCheckboxChange.bind(this)}
                                        disabled={this.state.isFiling || this.state.isLoadingProjects}
                                    />
                                </div>
                            ) : (
                                <ReminderComponent
                                    onChange={this.onReminderChange.bind(this)}
                                    className="newforma-formSpacing"
                                    max={this.reminderMaxValue}
                                    disabled={this.state.dueDate === undefined}
                                    isFiling={this.state.isFiling}
                                />
                            )}
                            <div className="newforma-flexRow newforma-formSpacing">
                                <KeywordsDropdown
                                    id="rfi-typePicker"
                                    className="newforma-rfiTypeDropdown"
                                    options={this.state.types}
                                    label={this.props.translate("RFI.TYPE.LABEL") as string}
                                    placeholder={this.props.translate("RFI.TYPE.PLACEHOLDER") as string}
                                    disabled={
                                        !this.state.selectedProject ||
                                        this.state.isLoadingKeywords ||
                                        this.state.isFiling ||
                                        !this.state.types.length
                                    }
                                    isLoading={this.state.isLoadingKeywords}
                                    isProjectSelected={!!this.state.selectedProject}
                                    required={!this.state.isCloudProject}
                                    onSelectionChange={this.onTypeSelectionChange.bind(this)}
                                    selectFirstOption={false}
                                    theme={this.props.theme}
                                    selectedPurposeKey={
                                        this.state.selectedType ? this.state.selectedType.displayOrder : null
                                    }
                                />
                                <div className="newforma-rfiSenderNumber">
                                    <Label required={false}>
                                        {this.props.translate("SUBMITTALS.SENDER_NUMBER_LABEL") as string}
                                    </Label>
                                    <TextField
                                        id="rfi-senderNumber"
                                        value={this.state.senderNumber}
                                        onChange={this.onSenderNumberChanged.bind(this)}
                                        disabled={this.state.isFiling || this.state.isLoadingProjects}
                                        maxLength={256}
                                    />
                                </div>
                            </div>
                            <FromAndToComponent
                                className={"newforma-formSpacing"}
                                isFromRequired={true}
                                isToRequired={true}
                                isCcRequired={false}
                                includeCc={false}
                                officeWrapper={this.props.officeWrapper}
                                disabled={this.state.isFiling || this.state.isLoadingProjects}
                                formValidationHelpers={this.props.formValidationHelpers}
                                mailboxItem={this.props.mailboxItem}
                                onFromChange={this.onFromChange.bind(this)}
                                onToChange={this.onToChange.bind(this)}
                                logger={this.props.logger}
                                projectsService={this.props.projectsService}
                                onError={this.props.onShowToast}
                                project={this.state.selectedProject}
                                shouldHideFrom={false}
                            />
                            <Label required={true}>{this.props.translate("RFI.QUESTION_LABEL") as string}</Label>
                            {this.props.isFileTransferAndEditorSupported ? (
                                <HTMLEditor
                                    value={this.state.question}
                                    onRemarksUpdate={this.onQuestionChange.bind(this)}
                                    configService={this.props.configService}
                                    isFiling={this.state.isFiling}
                                />
                            ) : (
                                <TextField
                                    className="newforma-formSpacing"
                                    id="rfi-question"
                                    multiline
                                    resizable={true}
                                    value={this.state.question}
                                    onChange={this.onOldEditorQuestionChange.bind(this)}
                                    disabled={this.state.isFiling || this.state.isLoadingProjects}
                                    rows={5}
                                    maxLength={65000}
                                />
                            )}
                            <Label required={false}>{this.props.translate("RFI.SUGGESTIONS_LABEL") as string}</Label>
                            <TextField
                                className="newforma-formSpacing"
                                id="rfi-suggestions"
                                multiline
                                resizable={true}
                                value={this.state.suggestions}
                                onChange={this.onSuggestionsChange.bind(this)}
                                disabled={this.state.isFiling || this.state.isLoadingProjects}
                                rows={5}
                                maxLength={65000}
                            />
                            <Label required={false}>{this.props.translate("RFI.INTERNAL_NOTES_LABEL") as string}</Label>
                            <TextField
                                className="newforma-formSpacing newforma-rfiInternalNotes"
                                id="rfi-internalNotes"
                                multiline={this.state.internalNotes.length > 50}
                                resizable={true}
                                value={this.state.internalNotes}
                                onChange={this.onInternalNotesChange.bind(this)}
                                disabled={this.state.isFiling || this.state.isLoadingProjects}
                                autoAdjustHeight={true}
                                maxLength={65000}
                            />
                            {this.state.isCloudProject ? (
                                <div className="newforma-flexRow newforma-formSpacing">
                                    <KeywordsDropdown
                                        id="rfi-impactSchedule"
                                        className="newforma-rfiImpactSchedule"
                                        options={this.state.impactSchedules}
                                        label={this.props.translate("RFI.IMPACT.SCHEDULE_LABEL") as string}
                                        placeholder={this.props.translate("RFI.IMPACT.PLACEHOLDER") as string}
                                        disabled={
                                            !this.state.selectedProject ||
                                            this.state.isLoadingKeywords ||
                                            this.state.isFiling ||
                                            !this.state.impactSchedules.length
                                        }
                                        isLoading={this.state.isLoadingKeywords}
                                        isProjectSelected={!!this.state.selectedProject}
                                        required={false}
                                        onSelectionChange={this.onImpactScheduleChange.bind(this)}
                                        selectFirstOption={false}
                                        theme={this.props.theme}
                                    />
                                    <KeywordsDropdown
                                        id="rfi-impactCost"
                                        className="newforma-rfiImpactCost"
                                        options={this.state.impactCosts}
                                        label={this.props.translate("RFI.IMPACT.COST_LABEL") as string}
                                        placeholder={this.props.translate("RFI.IMPACT.PLACEHOLDER") as string}
                                        disabled={
                                            !this.state.selectedProject ||
                                            this.state.isLoadingKeywords ||
                                            this.state.isFiling ||
                                            !this.state.impactCosts.length
                                        }
                                        isLoading={this.state.isLoadingKeywords}
                                        isProjectSelected={!!this.state.selectedProject}
                                        required={false}
                                        onSelectionChange={this.onImpactCostChange.bind(this)}
                                        selectFirstOption={false}
                                        theme={this.props.theme}
                                    />
                                </div>
                            ) : (
                                <KeywordsDropdown
                                    id="rfi-purposePicker"
                                    className="newforma-rfiPurpose"
                                    options={this.state.purposes}
                                    label={this.props.translate("RFI.PURPOSE.LABEL") as string}
                                    placeholder={this.props.translate("RFI.PURPOSE.PLACEHOLDER") as string}
                                    disabled={
                                        !this.state.selectedProject ||
                                        this.state.isLoadingKeywords ||
                                        this.state.isFiling ||
                                        !this.state.purposes.length
                                    }
                                    isLoading={this.state.isLoadingKeywords}
                                    isProjectSelected={!!this.state.selectedProject}
                                    required={true}
                                    onSelectionChange={this.onPurposeSelectionChange.bind(this)}
                                    selectFirstOption={false}
                                    theme={this.props.theme}
                                    selectedPurposeKey={
                                        this.state.selectedPurpose ? this.state.selectedPurpose.displayOrder : null
                                    }
                                />
                            )}
                            <ChipPickerComponent
                                className="newforma-formSpacing newforma-rfiReason"
                                required={false}
                                disabled={
                                    this.state.isFiling || this.state.isLoadingProjects || !this.state.selectedProject
                                }
                                items={[]}
                                selectedItems={this.state.selectedReasons}
                                onSelectedItemsChanged={this.onReasonsChange.bind(this)}
                                label={this.props.translate("RFI.REASON_LABEL") as string}
                                allowCustomInput={false}
                                keywordFilterProps={{
                                    filter: this.props.projectsService.getProjectKeywords.bind(
                                        this.props.projectsService
                                    ),
                                    projectNrn: this.state.selectedProject?.key,
                                    keywordListType: KeywordListType.RfiReasons,
                                    onError: this.handleKeywordError.bind(this),
                                }}
                            />
                            <ChipPickerComponent
                                className="newforma-formSpacing newforma-rfiDiscipline"
                                required={false}
                                disabled={
                                    this.state.isFiling || this.state.isLoadingProjects || !this.state.selectedProject
                                }
                                items={[]}
                                selectedItems={this.state.selectedDisciplines}
                                onSelectedItemsChanged={this.onDisciplinesChange.bind(this)}
                                label={this.props.translate("RFI.DISCIPLINE_LABEL") as string}
                                allowCustomInput={false}
                                keywordFilterProps={{
                                    filter: this.props.projectsService.getProjectKeywords.bind(
                                        this.props.projectsService
                                    ),
                                    projectNrn: this.state.selectedProject?.key,
                                    keywordListType: KeywordListType.RfiDisciplines,
                                    onError: this.handleKeywordError.bind(this),
                                }}
                            />
                            <ChipPickerComponent
                                className="newforma-formSpacing newforma-rfiKeywords"
                                required={false}
                                disabled={
                                    this.state.isFiling || this.state.isLoadingProjects || !this.state.selectedProject
                                }
                                items={[]}
                                selectedItems={this.state.selectedKeywords}
                                onSelectedItemsChanged={this.onKeywordsChange.bind(this)}
                                label={this.props.translate("RFI.KEYWORDS_LABEL") as string}
                                allowCustomInput={true}
                                keywordFilterProps={{
                                    filter: this.props.projectsService.getProjectKeywords.bind(
                                        this.props.projectsService
                                    ),
                                    projectNrn: this.state.selectedProject?.key,
                                    keywordListType: KeywordListType.RfiKeywords,
                                    onError: this.handleKeywordError.bind(this),
                                }}
                            />
                            {!this.state.isCloudProject ? (
                                <Dropdown
                                    className="newforma-formSpacing newforma-rfiContractsDropdown"
                                    options={this.state.contracts}
                                    placeholder={this.getContractsPlaceholder()}
                                    label={this.props.translate("RFI.CONTRACT.LABEL") as string}
                                    responsiveMode={ResponsiveMode.large}
                                    disabled={
                                        !this.state.selectedProject ||
                                        this.state.isLoadingKeywords ||
                                        this.state.isFiling ||
                                        !this.state.contracts.length
                                    }
                                    onChange={this.onContractSelectionChange.bind(this)}
                                    selectedKey={this.state.selectedContractKey}
                                    required={false}
                                    styles={{
                                        dropdown: {
                                            selectors: {
                                                "& :hover": {
                                                    borderColor: `${theme.palette.themeTertiary} !important`,
                                                },
                                            },
                                        },
                                    }}
                                />
                            ) : null}
                            <AttachmentsComponent
                                attachments={this.state.attachments}
                                disabled={this.state.isFiling}
                                allowFileUploads={true}
                                onAttachmentsUpdated={this.onAttachmentsUpdated.bind(this)}
                                isCloudProject={this.state.isCloudProject}
                                logger={this.props.logger}
                                isFilePathInAttachmentsSupported={this.props.isFilePathInAttachmentsSupported}
                                classNameForScroll=".newforma-rfiComponent"
                            />

                            {this.props.isForwardRfiSupported ? (
                                <ForwardCheckbox
                                    label={this.props.translate("RFI.FORWARD_RFI.FORWARD") as string}
                                    subtext={this.props.translate("RFI.FORWARD_RFI.FORWARD_SUBTEXT") as string}
                                    shouldForward={this.updateForwardRfiCheckbox.bind(this)}
                                    checked={this.state.shouldForwardRfi}
                                    isFiling={this.state.isFiling}
                                />
                            ) : null}
                        </>
                    )}
                </div>
                {this.state.fileUploadIsInProgress ? (
                    <ProgressIndicator
                        label={this.props.translate("SHARED.FILE_UPLOAD_PROGRESS_LABEL") as string}
                        className="newforma-progressIndicator"
                        styles={{
                            itemName: ".ms-label",
                        }}
                    />
                ) : null}
                <div id="footer" key="footer" className="newforma-footer">
                    <DefaultButton
                        key="fileAsRfiSubmitButton"
                        className="newforma-footerButton"
                        id="fileRfiButton"
                        primary={true}
                        onClick={
                            this.state.shouldForwardRfi
                                ? this.state.shouldShowForwardRfiOptions
                                    ? this.onFormSubmit.bind(this)
                                    : () => this.moveToForwardRfi()
                                : this.onFormSubmit.bind(this)
                        }
                        text={
                            this.state.shouldShowForwardRfiOptions
                                ? (this.props.translate("RFI.FORWARD_RFI.BUTTON") as string).toLocaleUpperCase()
                                : this.state.shouldForwardRfi
                                ? (this.props.translate(
                                      "RFI.FORWARD_RFI.CONTINUE_BUTTON"
                                  ) as string).toLocaleUpperCase()
                                : (this.props.translate("RFI.SUBMIT_BUTTON") as string).toLocaleUpperCase()
                        }
                        disabled={!this.isFormValid() || this.state.isFiling}
                    />
                </div>
            </div>
        );
    }
}

export default withLocalize(RfiComponent);
