import * as React from "react";
import "./SpecSectionComponent.less";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import {
    DirectionalHint,
    getTheme,
    HoverCard,
    HoverCardType,
    Icon,
    ISuggestionItemProps,
    ITag,
    Label,
    TagPicker,
    TextField,
    ValidationState,
} from "office-ui-fabric-react";
import { IProjectsService } from "../../../services/NewformaApi/IProjectsService";
import { KeywordListType } from "../../../models/ProjectKeywordsResponse";
import SuggestedItemWithSubtextComponent from "../pickerSuggestedItems/suggestedItemWithSubtext/SuggestedItemWithSubtextComponent";
import { MailboxItem } from "../../../services/OfficeWrapper";
import LabelComponent from "../label/LabelComponent";
import { FormValidationHelpers } from "../../../helpers/FormValidationHelpers";
import { NumberEntry } from "../../../models/projects/ProjectSettingsResponse";
import LinkComponent from "../linkComponent/LinkComponent";

export interface SpecSectionComponentProps extends LocalizeContextProps {
    projectsService: IProjectsService;
    project: ITag | null;
    className?: string;
    disabled: boolean;
    onChange: (
        specSectionName: string,
        itemNumber: string,
        revisionNumber: string,
        isRevisionNumberEnabled: boolean
    ) => void;
    onError: (error: any) => void;
    showSpecNumber: boolean;
    hideRevision?: boolean;
    keywordListType: KeywordListType.SubmittalSpecSection | KeywordListType.RfiSpecSection;
    mailboxItem: MailboxItem | null;
    required: boolean;
    formValidationHelpers: FormValidationHelpers;
    numberEntryMode: NumberEntry | null;
    clearSpecSection: boolean;
    selectedSpecSection?: ITag | undefined;
    selectedItemNumber?: string;
    selectedRevisionNumber?: string;
    onSpecChange?: (item: ITag | undefined) => void;
    onSubmittalNumberChange?: (value: string) => void;
    onRevisionNumberChange?: (value: string) => void;
}

export interface SpecSectionComponentState {
    specSections: ITag[];
    specSection: ITag | undefined;
    itemNumber: string;
    revisionNumber: string;
    isRevisionNumberDisplayed: boolean;
    tagPickerKey: number;
    specCalloutWidth: number;
}

class SpecSectionComponent extends React.Component<SpecSectionComponentProps, SpecSectionComponentState> {
    constructor(props: SpecSectionComponentProps, context: SpecSectionComponentState) {
        super(props, context);

        this.state = {
            specSections: [],
            specSection: undefined,
            itemNumber: "",
            revisionNumber: "",
            isRevisionNumberDisplayed: false,
            tagPickerKey: 0,
            specCalloutWidth: 255,
        };
    }

    componentDidMount(): void {
        if (this.props.selectedSpecSection) {
            this.setState({
                specSection: this.props.selectedSpecSection,
                itemNumber: this.props.selectedItemNumber ? this.props.selectedItemNumber : "",
                revisionNumber: this.props.selectedRevisionNumber ? this.props.selectedRevisionNumber : "",
            });
        }
    }

    componentDidUpdate(prevProps: SpecSectionComponentProps, prevState: SpecSectionComponentState) {
        if (
            prevProps.project?.name !== this.props.project?.name ||
            prevProps.mailboxItem !== this.props.mailboxItem ||
            (this.props.clearSpecSection && this.state.specSection !== undefined)
        ) {
            this.setState((state) => ({
                specSection: undefined,
                itemNumber: "",
                revisionNumber: "",
                isRevisionNumberDisplayed: false,
                tagPickerKey: state.tagPickerKey + 1,
            }));
            this.props.onChange("", "", "", false);
        }
    }

    private async onSpecSectionFiltered(filter: string, selectedItems?: ITag[]): Promise<ITag[]> {
        if (!this.props.project || !filter) {
            this.setState({ specSections: [] });
            return [];
        }
        this.updateProjectsCalloutWidth();

        let response;
        try {
            response = await this.props.projectsService.getProjectKeywords(
                this.props.project.key,
                this.props.keywordListType,
                filter
            );
        } catch (error) {
            this.props.onError(error);
            return [];
        }

        const specSections = response.items.map((x) => ({ name: x.name, key: x.description || "" }));
        this.setState({ specSections });
        return specSections;
    }

    private onSpecSectionSelected(selectedItem?: ITag): ITag | null {
        if (!selectedItem) {
            this.clearSpecSection();
            return null;
        }

        this.setState({ specSection: selectedItem });
        if (this.props.onSpecChange) {
            this.props.onSpecChange(selectedItem);
        }
        this.props.onChange(
            selectedItem.name,
            this.state.itemNumber,
            this.state.revisionNumber,
            this.state.isRevisionNumberDisplayed
        );

        return selectedItem;
    }

    private onSpecSectionChange(items?: ITag[]): void {
        if (!items?.length) {
            this.clearSpecSection();
        }
    }

    private clearSpecSection(): void {
        this.setState({
            specSection: undefined,
            revisionNumber: "",
            isRevisionNumberDisplayed: false,
        });

        if (!this.props.showSpecNumber) {
            this.setState({ itemNumber: "" });
        }

        this.props.onChange("", this.state.itemNumber, "", false);
    }

    private onNumberChange(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void {
        const submittalNumber = newValue?.trim() ? newValue : "";
        this.setState({ itemNumber: submittalNumber, revisionNumber: "" });
        this.props.onSubmittalNumberChange ? this.props.onSubmittalNumberChange(submittalNumber) : null;
        this.props.onChange(this.getSafeSpecSectionName(), submittalNumber, "", this.state.isRevisionNumberDisplayed);
    }

    private onNumberFocus(): void {
        if (!this.state.itemNumber && this.state.specSection && this.props.numberEntryMode === null) {
            const itemNumber = `${this.state.specSection.name} - `;
            this.setState({ itemNumber: itemNumber });
            this.props.onChange(
                this.state.specSection?.name,
                itemNumber,
                this.state.revisionNumber,
                this.state.isRevisionNumberDisplayed
            );
        }
    }

    private onNumberBlur(): void {
        if (!this.props.showSpecNumber && !this.isSpecNumberDirty()) {
            this.setState({ itemNumber: "", isRevisionNumberDisplayed: false });
            this.props.onChange(this.getSafeSpecSectionName(), "", "", false);
        }
    }

    private onRevisionChange(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void {
        this.setState({ revisionNumber: newValue || "" });
        this.props.onRevisionNumberChange && newValue ? this.props.onRevisionNumberChange(newValue) : null;
        this.props.onChange(
            this.getSafeSpecSectionName(),
            this.state.itemNumber,
            newValue || "",
            this.state.isRevisionNumberDisplayed
        );
    }

    private onAddRevision(): void {
        this.setState({ isRevisionNumberDisplayed: true });
        this.props.onChange(this.getSafeSpecSectionName(), this.state.itemNumber, this.state.revisionNumber, true);
    }

    private onRemoveRevision(): void {
        this.setState({ isRevisionNumberDisplayed: false, revisionNumber: "" });
        this.props.onChange(this.getSafeSpecSectionName(), this.state.itemNumber, "", false);
    }

    private isSpecNumberDirty(): boolean {
        if (!this.state.specSection || !this.state.itemNumber) {
            return false;
        }

        return this.state.itemNumber.trim() !== `${this.state.specSection.name} - `.trim();
    }

    private getSafeSpecSectionName(): string {
        return this.state.specSection?.name || "";
    }

    private onRenderSuggestedSpecs(props: ITag, itemProps: ISuggestionItemProps<any>): JSX.Element {
        return <SuggestedItemWithSubtextComponent primaryText={props.name} subtext={props.key} />;
    }

    onCreateGenericItem(input: string, validationState: ValidationState): ITag {
        const specNumberMatcher = /^( *\d){6}/g;
        const inputArray = input.match(specNumberMatcher);
        const specNumberNoSpaces = !!inputArray ? inputArray[0].replace(/\s/g, "") : "";
        const inputNoSpecNumber = input.replace(specNumberMatcher, "");
        const specSectionArray = specNumberNoSpaces.match(/.{2}/g) || [];
        const formattedString = `${specSectionArray.join(" ")}${inputNoSpecNumber}`.trim();
        return { name: formattedString, key: "", ValidationState: validationState } as ITag;
    }

    private renderHint(): JSX.Element {
        return (
            <div className="newforma-hintText">
                <div>{this.props.translate("SUBMITTALS.SPEC_SECTION.INFO_TEXT1") as string}</div>
                <div>{this.props.translate("SUBMITTALS.SPEC_SECTION.INFO_TEXT2") as string}</div>
            </div>
        );
    }

    private renderLabelHint(): JSX.Element {
        return (
            <div className="newforma-hintText">
                <div>{this.props.translate("SUBMITTALS.SPEC_SECTION.LABEL") as string}</div>
            </div>
        );
    }

    private numberLabel(): string {
        return this.props.numberEntryMode === NumberEntry.manual
            ? (this.props.translate("SUBMITTALS.SPEC_SECTION.NUMBER_REQUIRED") as string)
            : (this.props.translate("SUBMITTALS.SPEC_SECTION.AUTO_NUMBER") as string);
    }

    private updateProjectsCalloutWidth(): void {
        const specComponentWidth = document.querySelector(".newforma-specSection")?.clientWidth || 255;
        this.setState({
            specCalloutWidth: specComponentWidth + 4,
        });
    }

    render(): JSX.Element {
        const theme = getTheme();
        return (
            <div className={`newforma-specSection ${this.props.className}`}>
                <div className="newforma-headerContainer">
                    <HoverCard
                        plainCardProps={{
                            onRenderPlainCard: this.renderLabelHint.bind(this),
                            directionalHint: DirectionalHint.topRightEdge,
                            gapSpace: 5,
                            calloutProps: {
                                isBeakVisible: true,
                                beakWidth: 10,
                                coverTarget: false,
                                directionalHintFixed: true,
                                styles: {
                                    calloutMain: {
                                        color: `${theme.palette.neutralPrimary} !important`,
                                    },
                                },
                            },
                        }}
                        instantOpenOnClick
                        type={HoverCardType.plain}
                    >
                        <LabelComponent
                            text={
                                this.props.translate("SUBMITTALS.SPEC_SECTION.LABEL").toString().length > 15
                                    ? this.props
                                          .translate("SUBMITTALS.SPEC_SECTION.LABEL")
                                          .toString()
                                          .substr(0, 16)
                                          .concat("...")
                                    : (this.props.translate("SUBMITTALS.SPEC_SECTION.LABEL") as string)
                            }
                            required={this.props.required}
                        />
                    </HoverCard>
                    <HoverCard
                        plainCardProps={{
                            onRenderPlainCard: this.renderHint.bind(this),
                            directionalHint: DirectionalHint.topRightEdge,
                            gapSpace: 5,
                            calloutProps: {
                                isBeakVisible: true,
                                beakWidth: 45,
                                coverTarget: false,
                                directionalHintFixed: true,
                                styles: {
                                    calloutMain: {
                                        color: `${theme.palette.neutralPrimary} !important`,
                                    },
                                },
                            },
                            style: { minHeight: 50 },
                        }}
                        instantOpenOnClick
                        type={HoverCardType.plain}
                    >
                        <Icon iconName="Info" className="newforma-hintIcon" />
                    </HoverCard>
                </div>
                <TagPicker
                    key={this.state.tagPickerKey}
                    className={`newforma-specSectionPicker ${
                        this.props.disabled ? "newforma-disabledProjectPicker" : ""
                    }`}
                    selectedItems={this.state.specSection ? [this.state.specSection] : []}
                    pickerSuggestionsProps={{
                        suggestionsHeaderText: this.props.translate("SUBMITTALS.SPEC_SECTION.PICKER_HEADER") as string,
                        noResultsFoundText: this.props.translate(
                            "SUBMITTALS.SPEC_SECTION.PICKER_NO_SPEC_SECTIONS"
                        ) as string,
                        suggestionsItemClassName: "newforma-specSectionCallout",
                    }}
                    onRenderSuggestionsItem={this.onRenderSuggestedSpecs.bind(this)}
                    disabled={this.props.disabled}
                    itemLimit={1}
                    onResolveSuggestions={this.onSpecSectionFiltered.bind(this)}
                    onItemSelected={this.onSpecSectionSelected.bind(this)}
                    onChange={this.onSpecSectionChange.bind(this)}
                    inputProps={{
                        placeholder: this.props.translate("SUBMITTALS.SPEC_SECTION.PICKER_PLACEHOLDER") as string,
                        className: "newforma-specSectionPlaceholder",
                    }}
                    pickerCalloutProps={{
                        className: "newforma-specCallout",
                        calloutWidth: this.state.specCalloutWidth,
                        calloutMaxWidth: this.state.specCalloutWidth,
                    }}
                    resolveDelay={300}
                    onValidateInput={this.props.formValidationHelpers.isSpecSectionValid}
                    createGenericItem={this.onCreateGenericItem.bind(this)}
                />
                {this.state.specSection || this.props.showSpecNumber ? (
                    <>
                        <div className="newforma-specSectionNumberContainer newforma-flexRow">
                            <div
                                className={`${
                                    this.state.isRevisionNumberDisplayed
                                        ? "newforma-specSectionNumberWithRevision"
                                        : "newforma-specSectionNumber"
                                }`}
                            >
                                <Label
                                    id="specSection-number-Label"
                                    required={this.props.numberEntryMode === NumberEntry.manual}
                                >
                                    {this.props.translate("SUBMITTALS.SPEC_SECTION.NUMBER_LABEL") as string}
                                </Label>
                                <TextField
                                    id="specSection-number"
                                    placeholder={this.numberLabel()}
                                    value={this.state.itemNumber}
                                    onChange={this.onNumberChange.bind(this)}
                                    disabled={this.props.disabled}
                                    maxLength={256}
                                    onFocus={this.onNumberFocus.bind(this)}
                                    onBlur={this.onNumberBlur.bind(this)}
                                />
                            </div>
                            {this.state.isRevisionNumberDisplayed && this.isSpecNumberDirty() ? (
                                <TextField
                                    className="newforma-specSectionRevision"
                                    id="specSection-revision"
                                    placeholder={this.props.translate("SUBMITTALS.SPEC_SECTION.AUTO_NUMBER") as string}
                                    required={false}
                                    value={this.state.revisionNumber}
                                    onChange={this.onRevisionChange.bind(this)}
                                    disabled={this.props.disabled}
                                    maxLength={256}
                                />
                            ) : null}
                        </div>
                        {this.isSpecNumberDirty() && !this.props.hideRevision ? (
                            <div className="newforma-revisionActions">
                                {!this.state.isRevisionNumberDisplayed ? (
                                    <LinkComponent
                                        text={this.props.translate("SUBMITTALS.SPEC_SECTION.ADD_REVISION") as string}
                                        onClick={this.onAddRevision.bind(this)}
                                        className="newforma-displayBlock"
                                    />
                                ) : (
                                    <LinkComponent
                                        text={this.props.translate("SUBMITTALS.SPEC_SECTION.REMOVE_REVISION") as string}
                                        onClick={this.onRemoveRevision.bind(this)}
                                        className="newforma-displayBlock"
                                    />
                                )}
                            </div>
                        ) : null}
                    </>
                ) : null}
            </div>
        );
    }
}

export default withLocalize(SpecSectionComponent);
