import { NewformaApiClient } from "./NewformaApiClient";
import { HttpRequestWrapper } from "../HttpRequestWrapper";
import { Logger } from "../Logger";
import { LogSubmittalRequest } from "../../models/workflow/submittal/LogSubmittalRequest";
import { Request } from "aws-sign-web";
import { ApiRequestErrorLevel, ApiRequestErrorWithMessage } from "../../models/ApiRequestErrorWithMessage";
import { LogSubmittalResponse } from "../../models/workflow/submittal/LogSubmittalResponse";
import { LogSubmittalObjectResponse } from "../../models/workflow/submittal/LogSubmittalAndForwardResponse";
import { ExpiredSessionError } from "../../models/ExpiredSessionError";
import { OutlookApiService } from "../OutlookApiService";
import { FileUploadApiService } from "./FileUploadApiService";
import { AttachmentDetailsMetadata } from "../../models/AttachmentDetailsMetadata";
import { AnalyticsActionType, AnalyticsCategoryType, AnalyticsManager } from "../AnalyticsManager";
import v4 = require("uuid/v4");
import { SubmittalReviewResponseBodyParams } from "../../models/workflow/submittal/SubmittalWorkflowActions";
import { WorkflowActionResponse } from "../../models/workflow/WorkflowActionResponse";
import { SubmittalDetailsResponse } from "../../models/workflow/submittal/SubmittalDetailsResponse";
import { ProjectKeywordsResponse } from "../../models/ProjectKeywordsResponse";
import { Submittal, SubmittalsResponse } from "../../models/workflow/submittal/SubmittalsResponse";
import { WorkflowActionType } from "../../models/workflow/WorkflowActionType";
import { EmailApiService } from "./EmailApiService";
import { NrnServiceWrapper } from "../NrnServiceWrapper";
import { Keyword } from "../../models/Keyword";
import { PostEmailFilingResponse } from "../../models/PostEmailFilingResponse";
import { AttachmentItem } from "../../models/shared/AttachmentList";
import { mapAttachmentsMetadataToAttachment } from "../../helpers/SendAndFile/AttachmentHelpers";
import { LogForwardSubmittalRequest } from "../../models/workflow/submittal/ForwardSubmittalRequest";
import { LogForwardSubmittalResponse } from "../../models/workflow/submittal/ForwardSubmittalResponse";

export enum SubmittalStatus {
    Closed = "closed",
    Forwarded = "forwarded",
    Open = "open",
}

export class SubmittalsApiService {
    constructor(
        private newformaApiClient: NewformaApiClient,
        private requestWrapper: HttpRequestWrapper,
        private logger: Logger,
        private outlookApiService: OutlookApiService,
        private fileUploadApiService: FileUploadApiService,
        private analyticsManager: AnalyticsManager,
        private emailApiService: EmailApiService,
        private nrnServiceWrapper: NrnServiceWrapper
    ) {}

    getVia(projectNrn: string): Keyword | undefined {
        if (!this.nrnServiceWrapper.isCloudProject(projectNrn)) {
            return { name: "Email", type: "transferMethod" };
        }
        return undefined;
    }

    async logEmailAsSubmittal(
        projectNrn: string,
        messageNrn: string,
        submittal: LogSubmittalRequest,
        attachments: AttachmentItem[],
        fileUploadCallback: (isInProgress: boolean, failedIds: string[]) => void,
        isLastModifiedDateSupported: boolean
    ): Promise<LogSubmittalObjectResponse> {
        let attachmentsDetails: AttachmentDetailsMetadata[] = [];

        if (attachments.length > 0) {
            attachmentsDetails = await this.outlookApiService.getFileAttachmentDetailsMetadata(
                attachments,
                isLastModifiedDateSupported
            );
            submittal.attachments = mapAttachmentsMetadataToAttachment(attachmentsDetails, isLastModifiedDateSupported);
        }

        let response: LogSubmittalResponse;

        try {
            response = await this.logSubmittal(submittal, projectNrn);
        } catch (error) {
            this.logger.error("SubmittalApiService. Logging a submittal", error);

            const handledErrors: { [index: string]: string } = {
                403: "SUBMITTALS.UNAUTHORIZED_ERROR",
                409: "SUBMITTALS.CREATE_FAILED_CONFLICT",
                500: "SUBMITTALS.UNEXPECTED_ERROR",
            };

            const messageToDisplay = handledErrors[(error as any).status];
            if (messageToDisplay) {
                throw new ApiRequestErrorWithMessage(
                    (error as any).message,
                    ApiRequestErrorLevel.ERROR,
                    messageToDisplay,
                    (error as any).status
                );
            }

            throw error;
        }

        if (attachments.length > 0) {
            const batchId = v4();
            const result = await this.fileUploadApiService.uploadWorkflowAttachments(
                attachmentsDetails,
                response.uploadFolderNrn,
                fileUploadCallback,
                batchId
            );
            fileUploadCallback(false, result.failedFileIds);
            if (result.failedFileIds.length > 0) {
                throw new ApiRequestErrorWithMessage(
                    "one or more files failed to upload",
                    ApiRequestErrorLevel.ERROR,
                    "SUBMITTALS.CREATE_FAILED_ATTACHMENTS_UPLOAD",
                    400
                );
            }
        }

        const filingProjectNrn = this.nrnServiceWrapper.isCloudProject(projectNrn) ? projectNrn : response.nrn;
        await this.fileEmailToProject(filingProjectNrn, messageNrn);
        return { submittalResponse: response };
    }

    async logEmailAsForwardSubmittal(
        forwardSubmittal: LogForwardSubmittalRequest,
        projectNrn: string,
        submittalNrn: string,
        attachments: AttachmentItem[],
        messageNrn: string,
        fileUploadCallback: (isInProgress: boolean, failedIds: string[]) => void,
        isLastModifiedDateSupported: boolean
    ): Promise<LogForwardSubmittalResponse> {
        let attachmentsDetails;
        let forwardedResponse;
        let forwardSuccess = false;
        const maxRetries = 3;
        let attempt = 0;
        const retryDelay = 5000; // 5 seconds

        function delay(ms: number) {
            return new Promise((resolve) => setTimeout(resolve, ms));
        }

        const forwardError: string | null = null;
        if (attachments.length > 0) {
            attachmentsDetails = await this.outlookApiService.getFileAttachmentDetailsMetadata(
                attachments,
                isLastModifiedDateSupported
            );
            forwardSubmittal.attachments = mapAttachmentsMetadataToAttachment(
                attachmentsDetails,
                isLastModifiedDateSupported
            );
        }
        while (attempt < maxRetries && !forwardSuccess) {
            try {
                attempt++;
                forwardedResponse = await this.logForwardSubmittal(forwardSubmittal, projectNrn, submittalNrn);
                forwardSuccess = true;
            } catch (error) {
                this.logger.error(
                    `SubmittalApiService - forward submittal attempt ${attempt}. Logging a forwarded submittal`,
                    error
                );
                if (attempt >= maxRetries) {
                    forwardSuccess = false;
                    const handledErrors: { [index: string]: string } = {
                        403: "SUBMITTALS.UNAUTHORIZED_ERROR",
                        409: "SUBMITTALS.CREATE_FAILED_CONFLICT",
                        500: "SUBMITTALS.UNEXPECTED_ERROR",
                    };

                    const errorCode = (error as any).response?.status || 500;
                    const errorMessage = handledErrors[errorCode] || "SUBMITTALS.UNEXPECTED_ERROR";

                    throw new Error(errorMessage);
                }
                await delay(retryDelay);
            }
        }
        if (attachments.length > 0) {
            const batchId = v4();
            const result = await this.fileUploadApiService.uploadWorkflowAttachments(
                attachmentsDetails || [],
                forwardedResponse.uploadFolderNrn,
                fileUploadCallback,
                batchId
            );
            fileUploadCallback(false, result.failedFileIds);
            if (result.failedFileIds.length > 0) {
                throw new ApiRequestErrorWithMessage(
                    "one or more files failed to upload",
                    ApiRequestErrorLevel.ERROR,
                    "SUBMITTALS.CREATE_FAILED_ATTACHMENTS_UPLOAD",
                    400
                );
            }
        }
        const filingProjectNrn = submittalNrn;
        await this.fileEmailToProject(filingProjectNrn, messageNrn);
        return { forwardSuccess, forwardError };
    }

    async logEmailAsSubmittalReviewerResponse(
        projectNrn: string,
        messageNrn: string,
        submittalNrn: string,
        params: SubmittalReviewResponseBodyParams,
        attachments: AttachmentItem[],
        fileUploadCallback: (isInProgress: boolean, failedIds: string[]) => void,
        isLastModifiedDateSupported: boolean
    ): Promise<WorkflowActionResponse> {
        this.analyticsManager.recordEvent(
            AnalyticsCategoryType.UserActions,
            AnalyticsActionType.FileEmailAsSubmittalReviewResponse
        );

        let attachmentsDetails: AttachmentDetailsMetadata[] = [];

        if (attachments.length > 0) {
            attachmentsDetails = await this.outlookApiService.getFileAttachmentDetailsMetadata(
                attachments,
                isLastModifiedDateSupported
            );
            params.attachments = mapAttachmentsMetadataToAttachment(attachmentsDetails, isLastModifiedDateSupported);
        }

        let response: WorkflowActionResponse;

        try {
            response = await this.logSubmittalReviewerResponse(params, projectNrn, submittalNrn);
        } catch (error) {
            this.logger.error("SubmittalApiService. Logging a submittal Reviewer Response", error);

            const handledErrors: { [index: string]: string } = {
                403: "SUBMITTALS.RESPONSE.UNAUTHORIZED_ERROR",
                409: "SUBMITTALS.RESPONSE.FAILED_CONFLICT",
                500: "SUBMITTALS.RESPONSE.FAILED_GENERIC",
            };

            const messageToDisplay = handledErrors[(error as any).status];
            if (messageToDisplay) {
                throw new ApiRequestErrorWithMessage(
                    (error as any).message,
                    ApiRequestErrorLevel.ERROR,
                    messageToDisplay,
                    (error as any).status
                );
            }

            throw error;
        }

        if (attachments.length > 0) {
            const batchId = v4();
            const result = await this.fileUploadApiService.uploadWorkflowAttachments(
                attachmentsDetails,
                response.uploadFolderNrn,
                fileUploadCallback,
                batchId
            );
            fileUploadCallback(false, result.failedFileIds);
            if (result.failedFileIds.length > 0) {
                throw new ApiRequestErrorWithMessage(
                    "one or more files failed to upload",
                    ApiRequestErrorLevel.ERROR,
                    "SUBMITTALS.RESPONSE.FAILED_ATTACHMENTS_UPLOAD",
                    400
                );
            }
        }

        const filingNrn = this.nrnServiceWrapper.isCloudProject(projectNrn) ? projectNrn : submittalNrn;
        await this.fileEmailToProject(filingNrn, messageNrn);

        return response;
    }

    async getSubmittalDetails(submittalNrn: string, projectNrn: string): Promise<SubmittalDetailsResponse> {
        const url = `${this.newformaApiClient.getHostNameWithProtocol()}/v1/projects/${encodeURIComponent(
            projectNrn
        )}/submittals/${encodeURIComponent(submittalNrn)}`;

        const options: Request = {
            url: url,
            method: "GET",
            headers: {
                "x-newforma-agent": this.newformaApiClient.getNewformaAgent(),
            },
        };
        this.logger.info("Fetching submittal details");
        return this.newformaApiClient.makeRequest(options, (signedOptions) =>
            this.requestWrapper.get(signedOptions.url, undefined, signedOptions.headers, undefined)
        );
    }

    async getWorkflowActionKeywords(
        projectNrn: string,
        actionType: WorkflowActionType
    ): Promise<ProjectKeywordsResponse> {
        const url = `${this.newformaApiClient.getHostNameWithProtocol()}/v1/projects/${encodeURIComponent(
            projectNrn
        )}/submittals/workflowactions/${actionType}/keywords`;

        const options: Request = {
            url: url,
            method: "GET",
            headers: {
                "x-newforma-agent": this.newformaApiClient.getNewformaAgent(),
            },
        };

        this.logger.info(`Retrieving submittal keywords for action: ${actionType}`);
        return this.newformaApiClient.makeRequest(options, (signedOptions) =>
            this.requestWrapper.get(signedOptions.url, undefined, signedOptions.headers, undefined)
        );
    }

    async getForwardedSubmittals(projectNrn: string): Promise<Submittal[]> {
        const isCloudProject = this.nrnServiceWrapper.isCloudProject(projectNrn);
        const result = await this.getSubmittals(projectNrn, undefined, undefined);

        let offsetToken = result.paging?.offsetToken;
        while (offsetToken) {
            const nextPage = await this.getSubmittals(projectNrn, undefined, offsetToken);
            result.items = result.items.concat(nextPage.items);
            offsetToken = nextPage.paging?.offsetToken;
        }

        result.paging = undefined;

        let forwardedSubmittals = [];

        if (isCloudProject) {
            forwardedSubmittals = result.items.filter(
                (submittal) => submittal.status.type === SubmittalStatus.Forwarded
            );
        } else {
            forwardedSubmittals = result.items.filter(
                (submittal) =>
                    submittal.status.type === SubmittalStatus.Forwarded ||
                    submittal.status.type === SubmittalStatus.Open
            );
        }
        return forwardedSubmittals;
    }

    private logSubmittal(submittal: LogSubmittalRequest, projectNrn: string): Promise<LogSubmittalResponse> {
        const options: Request = {
            url: `${this.newformaApiClient.getHostNameWithProtocol()}/v1/projects/${projectNrn}/submittals/log`,
            method: "POST",
            body: JSON.stringify(submittal),
            headers: {
                "x-newforma-agent": this.newformaApiClient.getNewformaAgent(),
            },
        };

        this.logger.info("Logging a submittal");
        return this.newformaApiClient.makeRequest(options, (signedOptions) =>
            this.requestWrapper.post(signedOptions.url, undefined, signedOptions.headers, signedOptions.body)
        );
    }

    private logForwardSubmittal(
        submittal: LogForwardSubmittalRequest,
        projectNrn: string,
        submittalNrn: string
    ): Promise<any> {
        const url = `${this.newformaApiClient.getHostNameWithProtocol()}/v1/projects/${encodeURIComponent(
            projectNrn
        )}/submittals/${encodeURIComponent(submittalNrn)}/${WorkflowActionType.Forward.toLocaleLowerCase()}`;
        const options: Request = {
            url: url,
            method: "PUT",
            body: JSON.stringify(submittal),
            headers: {
                "x-newforma-agent": this.newformaApiClient.getNewformaAgent(),
            },
        };

        this.logger.info("Logging a FORWARDED submittal");
        return this.newformaApiClient.makeRequest(options, (signedOptions) =>
            this.requestWrapper.put(signedOptions.url, undefined, signedOptions.headers, signedOptions.body)
        );
    }

    private async logSubmittalReviewerResponse(
        reviewerResponse: SubmittalReviewResponseBodyParams,
        projectNrn: string,
        submittalNrn: string
    ): Promise<WorkflowActionResponse> {
        const url = `${this.newformaApiClient.getHostNameWithProtocol()}/v1/projects/${encodeURIComponent(
            projectNrn
        )}/submittals/${encodeURIComponent(submittalNrn)}/${WorkflowActionType.ReviewResponse.toLocaleLowerCase()}`;

        const options: Request = {
            url: url,
            method: "PUT",
            body: JSON.stringify(reviewerResponse),
            headers: {
                "x-newforma-agent": this.newformaApiClient.getNewformaAgent(),
            },
        };

        this.logger.info("Logging a submittal reviewer response");
        return this.newformaApiClient.makeRequest(options, (signedOptions) =>
            this.requestWrapper.put(signedOptions.url, undefined, signedOptions.headers, signedOptions.body)
        );
    }

    private async fileEmailToProject(projectNrn: string, messageNrn: string): Promise<PostEmailFilingResponse> {
        this.logger.info("Logging a submittal - Filing email");

        try {
            return await this.emailApiService.fileToProject(projectNrn, messageNrn);
        } catch (error) {
            this.logger.error("SubmittalApiService. Logging a submittal - Filing email", error);

            if (ExpiredSessionError.isInstanceOf(error)) {
                throw error;
            }

            throw new ApiRequestErrorWithMessage(
                (error as any).message,
                ApiRequestErrorLevel.ERROR,
                "SUBMITTALS.CREATE_FAILED_EMAIL_NOT_CREATED",
                (error as any).status
            );
        }
    }

    private async getSubmittals(
        projectNrn: string,
        filter: string = "",
        offsetToken: string = ""
    ): Promise<SubmittalsResponse> {
        const maxPageSize = 50;
        const url = `${this.newformaApiClient.getHostNameWithProtocol()}/v1/projects/${projectNrn}/submittals?status=${filter}&maxSize=${maxPageSize}&offsetToken=${offsetToken}`;

        const options: Request = {
            url: url,
            method: "GET",
            headers: {
                "x-newforma-agent": this.newformaApiClient.getNewformaAgent(),
            },
        };

        this.logger.info("Retrieving submittals");
        return this.newformaApiClient.makeRequest(options, (signedOptions) =>
            this.requestWrapper.get(signedOptions.url, undefined, signedOptions.headers, undefined)
        );
    }
}
